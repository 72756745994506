@import url(https://fonts.googleapis.com/css?family=Sora:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Archivo:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.home {
    background-color: #f0f0f0;
    background-image: url("/images/minimalismbg.jpg");
    background-repeat: round;
}

.navbar {
    background-color: black;
    color: white;
    box-shadow: "0 1px 2px rgba(0, 0, 0, 0.08)";
    height: 48px;
    position: -webkit-sticky; /* This is needed for Safari support */
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 22px;
    margin-left: 0px;
    margin-top: 0px;
}

.titlefont {
    font-family: 'Sora', cursive;
    font-size: 40px;
    font-weight: bold;
}

.subtitlefont {
    font-size: 20px;
    font-family: 'Ubuntu', cursive;
    padding-top: 8px;
}

.textfontfamily {
    font-family: 'Ubuntu', cursive;
}

.leftbgoffset {
    margin-left: 0px;
}

.subtitleoffset {
    margin-left: 6px;
    font-size: 16px;
}

.pagemarginleft {
    margin-left: 24px;
}

.logodiv {
    float: right;
    width: 600px;
}

.contentdiv {
    float: left;
    width: 400px;
    height: 1200px;
    vertical-align: top;
}
.gradientbg {
    background-image: url("/images/gradientfill.png");
    background-repeat: repeat-x;
}

.bwlinesbg {
    background-repeat: no-repeat;
}

.grid-layout {
    margin-left: 50px;
    padding-right: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.grid-celltitle {
    width: 150px;
    vertical-align: top;
    padding-top: 8px;
    background-color: #f4f4f4;
}

.grid-cellsingleline {
    width: 150px;
    vertical-align: top;
    padding-top: 10px;
    background-color: #f4f4f4;
}
.grid-celllogo {
    width: 50px;
    padding-right: 10px;
    vertical-align: top;
    background-color: #f4f4f4;
}
.grid-cellbody {
    vertical-align: top;
    padding-top: 8px;
    background-color: #f4f4f4;
}

.slider-slides {
    width: 80%;
}
.slides {
    height: 300px;
    width: 80%;
}

.slide1a {
    background-color: #1c3d58;
}

.slide1b {
    background-color: #1c3d58;
}

.corner-curved {
    border-radius: 25px;
    border: 1px solid #000000;
    padding: 0px;
}

.slide-tile {
    vertical-align: center;
    horiz-align: center;
    margin: auto;
    margin-top: 30px;
}

.slidefont-large {
    font-size: 16px;
}

.slidefont-medium {
    font-size: 16px;
}

.carousel {
    padding-left: 0px;
    padding-right: 0px;
}

.silvertext {
    color: #e0e0e0;
    text-shadow: 1px 0px #000000;
}

.slidetitletext {
    color: #e0e0e0;
    text-shadow: 1px 0px #444444;
    font-size: 36px;
    font-weight: bold;
}

.slidetitletextblack {
    color: #000000;
    text-shadow: 1px 0px #cccccc;
    font-size: 36px;
    font-weight: bold;
}

.slidedevtitleoffset {
    margin-left: 20px;
}

.slidedevimageoffset {
    margin-left: 40px;
}

.slidesubtitletext {
    color: #e0e0e0;
    text-shadow: 1px 0px #444444;
    font-size: 28px;
    font-weight: bold;
}

.slidetext {
    color: #ffffff;
    font-size: 22px;
}

.slidelink {
    color: #ffffff;
    text-decoration: none;
}

.linkblack {
    color: #000000;
    text-decoration: none;
}

.linkwhite {
    color: #ffffff;
    text-decoration: underline;
}

.slidetextshadow {
    text-shadow:
            -1.0px -1.0px 0 #000000,
            1.0px -1.0px 0 #000000,
            -1.0px 1.0px 0 #000000,
            1.0px 1.0px 0 #000000;
}

.slidetextshadowblack {
    text-shadow:
            -1.5px -1.5px 0 #ffffff,
            1.5px -1.5px 0 #ffffff,
            -1.5px 1.5px 0 #ffffff,
            1.5px 1.5px 0 #ffffff;
    color: black;
}

.slidedevleftoffset {
    margin-left: 80px;
}

.slidedevtopoffset {
    margin-left: 80px;
}

.slide {
    width: 50%;
}

.slidesdr {
    background-image: url("/images/rx51-sdrscan-lte800-20181106c.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.slidesigsite {
    background-image: url("/images/sigsitebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.slidesimprovisioning {
    background-image: url("/images/reddotbg2.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.slidevault {
    background-image: url("/images/vaultbg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.slidehsm {
    background-image: url("/images/corenetfaded.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.panel-table {
    background-color: #000000;
    padding: 0px;
    margin: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    font-family: 'Ubuntu', cursive;
}

.panel-title {
    background-color: #000000;
    font-size: 20px;
    color: white;
    font-family: 'Ubuntu', cursive;
    padding: 4px;
}

.panel-body {
    background-color: #ffffff;
    font-size: 18px;
    padding: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.panel-link {
    color: #000000;
    text-decoration: none;
}

.email {
    color: red;
}

.copyright {
}

.logostream {
    padding-left: 40px;
}
